import React, { useState } from 'react';

/*
"WithOverridableStyle" is used to merge component's default CSS Module and with CSS Module from
the prop: "styles". This allows parent component to change style of the child component.
To use this:
- export default WithOverridableStyle([Component], [imported CSS Module])
- In [Component] props there is now a "styles" prop which is a resulting CSS Module
Note: Because of the naming conflict imported CSS Module must not be called "styles".
*/

const mergeStyles = (defaultStyle, overrideStyle) => {
  const merged = { ...defaultStyle };

  if (overrideStyle) {
    Object.keys(defaultStyle).forEach(key => {
      const baseName = defaultStyle[key];
      const overrideName = overrideStyle[key];
      if (baseName.indexOf(overrideName) === -1) {
        merged[key] = `${baseName} ${overrideName}`;
      }
    });
  }

  return merged;
};

const WithOverridableStyle = (WrappedComponent, defaultStyle) => {
  const StyleOverrideWrapper = props => {
    // eslint-disable-next-line react/prop-types
    const { styles, ...rest } = props;
    const [mergedStyles] = useState(() => mergeStyles(defaultStyle, styles));

    return <WrappedComponent {...rest} styles={mergedStyles} />;
  };

  return StyleOverrideWrapper;
};

export default WithOverridableStyle;
