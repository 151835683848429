import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/Checkbox/Checkbox.scss';
import classNames from 'classnames';

class Checkbox extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    /** value of the checkbox */
    value: PropTypes.string,
    /** value of the preselection of the checkbox */
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    /** function for the onChange event of the checkbox */
    onChange: PropTypes.func,
    tabIndex: PropTypes.number,
    className: PropTypes.string,
    hint: PropTypes.string,
  };

  static defaultProps = {
    id: '',
    value: '',
    checked: false,
    disabled: false,
    onChange: () => {},
    tabIndex: 0,
    className: '',
    hint: null,
  };

  constructor(props) {
    super(props);
    this.checkboxRef = React.createRef();
  }

  handleLabelMouseDown = event => {
    event.preventDefault();

    if (this.checkboxRef) {
      this.checkboxRef.current.focus();
    }
  };

  focus() {
    if (this.checkboxRef && document.activeElement !== this.checkboxRef) {
      this.checkboxRef.current.focus();
    }
  }

  render() {
    const {
      id,
      checked,
      disabled,
      tabIndex,
      onChange,
      value,
      hint,
    } = this.props;

    const className = classNames(styles.wrapper, this.props.className, {
      [styles.checked]: checked,
      [styles.disabled]: disabled,
      [styles.error]: hint,
    });

    return (
      <label
        className={className}
        htmlFor={id}
        onMouseDown={this.handleLabelMouseDown}
      >
        <input
          className={styles.control}
          type="checkbox"
          id={id}
          value={value}
          checked={checked}
          tabIndex={tabIndex}
          ref={this.checkboxRef}
          onChange={onChange}
          aria-checked={checked}
          aria-disabled={disabled}
          aria-describedby={hint ? `${id}-hint` : undefined}
        />
        {hint && (
          <span id={`${id}-hint`} className={styles.hint}>
            {hint}
          </span>
        )}
      </label>
    );
  }
}

export default Checkbox;
