import api from 'api';
/*
*******
Actions
*******
*/

export const getNavigation = () => dispatch =>
  api
    .getNavLinks()
    .main()
    .then(({ data }) => {
      if (!data) {
        dispatch({
          type: 'FETCH_NAVIGATION',
        });
        return;
      }
      dispatch({
        type: 'FETCH_NAVIGATION_SUCCESS',
        payload: data,
      });
    })
    .catch(() =>
      dispatch({
        type: 'FETCH_NAVIGATION_FAILURE',
      }),
    );

export const getFooterNavigation = () => dispatch =>
  api
    .getNavLinks()
    .footer()
    .then(({ data }) => {
      if (!data) {
        dispatch({
          type: 'FETCH_FOOTER_NAVIGATION',
        });
        return;
      }
      dispatch({
        type: 'FETCH_FOOTER_NAVIGATION_SUCCESS',
        payload: data,
      });
    })
    .catch(() =>
      dispatch({
        type: 'FETCH_FOOTER_NAVIGATION_FAILURE',
      }),
    );

export const setMenuStatus = status => ({
  type: 'TOGGLE_MENU',
  payload: status,
});

export const setShrink = status => ({
  type: 'SET_SHRINK',
  payload: status,
});

export const updateCookieConsent = isAccepted => ({
  type: 'UPDATE_COOKIE_CONSENT',
  payload: isAccepted,
});

/*
*******
Reducer
*******
*/

const initState = {
  loading: false,
  menu: {
    isMenuOpen: false,
    shrinked: false,
    navItems: null,
  },
  footer: null,
  isCookieConsentAccepted: null,
};

export const app = (state = initState, action) => {
  switch (action.type) {
    case 'FETCH_NAVIGATION':
    case 'FETCH_FOOTER_NAVIGATION':
      return {
        ...state,
        loading: true,
      };

    case 'FETCH_NAVIGATION_SUCCESS':
      return {
        ...state,
        loading: false,
        generalEmail: action.payload.generalEmail,
        menu: {
          ...state.menu,
          navItems: action.payload.data,
        },
      };

    case 'FETCH_FOOTER_NAVIGATION_SUCCESS':
      return {
        ...state,
        loading: false,
        footer: action.payload,
      };

    case 'FETCH_NAVIGATION_FAILURE':
    case 'FETCH_FOOTER_NAVIGATION_FAILURE':
      return {
        ...state,
        loading: false,
      };

    case 'TOGGLE_MENU':
      return {
        ...state,
        menu: {
          ...state.menu,
          isMenuOpen: action.payload,
        },
      };

    case 'SET_SHRINK':
      return {
        ...state,
        menu: {
          ...state.menu,
          shrinked: action.payload,
        },
      };

    case 'UPDATE_COOKIE_CONSENT':
      return {
        ...state,
        isCookieConsentAccepted: action.payload,
      };

    default:
      return state;
  }
};
