import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from 'components/Card/components/CardAction/CardAction.scss';

class CardAction extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]).isRequired,
    ribbonPosition: PropTypes.oneOf(['top', 'bottom']),
  };

  static defaultProps = {
    ribbonPosition: 'top',
  };

  render() {
    const { children, ribbonPosition } = this.props;
    return (
      <div
        className={styles.container}
        role="contentinfo"
        aria-label="Card Action"
      >
        <div className={styles.wrapper}>{children}</div>
        <div
          className={classNames(styles.ribbon, styles[ribbonPosition])}
        ></div>
      </div>
    );
  }
}

export default CardAction;
