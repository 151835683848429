import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'components/Wrapper/Wrapper.scss';

import 'assets/images/brand/apple-touch-icon.png';
import 'assets/images/brand/favicon-32x32.png';
import 'assets/images/brand/favicon-16x16.png';
import 'assets/images/brand/safari-pinned-tab.svg';
import 'assets/images/brand/favicon.ico';

class Wrapper extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;

    return <BrowserRouter>{children}</BrowserRouter>;
  }
}

export default Wrapper;
