import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/Card/Card.scss';
import classNames from 'classnames';

import CardImage from 'components/Card/components/CardImage/CardImage';
import CardAction from 'components/Card/components/CardAction/CardAction';
import CardFeatures from 'components/Card/components/CardFeatures/CardFeatures';

class Card extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    padded: PropTypes.bool,
    noMargin: PropTypes.bool,
    noShadow: PropTypes.bool,
    fullHeight: PropTypes.bool,
    className: PropTypes.string,
    role: PropTypes.string,
  };

  static defaultProps = {
    padded: false,
    fullHeight: false,
    noMargin: false,
    noShadow: false,
    className: '',
    role: 'region',
  };

  render() {
    const {
      children,
      padded,
      noMargin,
      noShadow,
      className,
      fullHeight,
      role,
    } = this.props;
    return (
      <div
        className={classNames(
          styles.card,
          {
            [styles.padded]: padded,
            [styles.fullHeight]: fullHeight,
            [styles.noMargin]: noMargin,
            [styles.noShadow]: noShadow,
          },
          className,
        )}
        role={role || 'region'}
        aria-label="Card"
      >
        {children}
      </div>
    );
  }
}

export { Card as default, CardImage, CardAction, CardFeatures };
