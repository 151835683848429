import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input/Input';
import * as iconList from '@moonshineragency/icons';

class BaseInput extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    /* to control how the element should look like,you can write those options in ui:widget schema */
    options: PropTypes.shape({
      /* emptyValue is the default input value */
      emptyValue: PropTypes.string,
      /* helper message  to show the user how the value should look like */
      help: PropTypes.string,
      large: PropTypes.bool,
      small: PropTypes.bool,
      /* contains only the name of the icon */
      iconSuffix: PropTypes.string,
      /* contains only the name of the icon */
      iconPrefix: PropTypes.string,
      disabled: PropTypes.bool,
      readonly: PropTypes.bool,
    }),
    /* rawErrors come from  react-jsonschema-form, when the value was invalid */
    rawErrors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    insertbefore: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    style: PropTypes.objectOf(PropTypes.string),
    wrapperClassName: PropTypes.string,
    grid: PropTypes.arrayOf(PropTypes.string),
    rows: PropTypes.number,
  };

  static defaultProps = {
    value: '',
    type: 'text',
    title: '',
    rawErrors: null,

    required: false,
    placeholder: '',
    insertbefore: null,
    style: null,
    wrapperClassName: null,
    grid: null,
    rows: 1,
    options: {
      emptyValue: '',
      help: '',
      large: false,
      small: false,
      iconSuffix: null,
      iconPrefix: null,
      disabled: false,
      readonly: false,
    },
    onBlur: () => {},
    onFocus: () => {},
  };

  getHint = () => {
    const {
      rawErrors,
      options: { help },
    } = this.props;

    if (rawErrors) {
      return rawErrors.toString();
    }

    if (help) {
      return help;
    }

    return null;
  };

  getStatus = () => {
    const { rawErrors } = this.props;

    if (rawErrors) {
      return 'error';
    }

    return 'normal';
  };

  handleBlur = value => {
    if (this.props.onBlur) {
      this.props.onBlur(this.props.id, value);
    }
  };

  handleFocus = value => {
    if (this.props.onFocus) {
      this.props.onFocus(this.props.id, value);
    }
  };

  handleChange = value => {
    this.props.onChange(value === '' ? this.props.options.emptyValue : value);
  };

  render() {
    const {
      id,
      type,
      value,
      required,
      options: { disabled, readonly, large, small },
      options,
      placeholder,
      title,
      insertbefore,
      style,
      wrapperClassName,
      grid,
      rows,
    } = this.props;

    let iconPrefix = null;
    let iconSuffix = null;
    if (options.iconSuffix || options.iconPrefix) {
      iconSuffix = iconList[options.iconSuffix];
      iconPrefix = iconList[options.iconPrefix];
    }

    return (
      <Input
        name={id}
        id={id}
        type={type}
        // maxLength={schema.maxLength}
        value={value}
        label={title}
        large={large}
        small={small}
        isRequired={required}
        disabled={disabled}
        readonly={readonly}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        placeholder={placeholder}
        hint={this.getHint()}
        status={this.getStatus()}
        insertbefore={insertbefore}
        style={style}
        wrapperClassName={wrapperClassName}
        grid={grid}
        iconSuffix={iconSuffix}
        iconPrefix={iconPrefix}
        rows={rows}
        aria-required={required}
        aria-disabled={disabled}
        aria-invalid={this.getStatus() === 'error'}
      />
    );
  }
}

export default BaseInput;
