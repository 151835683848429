const models = client => ({
  getPageData: (slug, language) => client.get(`/pages/${language}/${slug}`),
  getNavLinks: () => ({
    main: () => client.get('/navigation'),
    footer: () => client.get('/navigation/footer'),
  }),
  settings: () => ({
    all: () => client.get('/settings'),
  }),
  programs: () => ({
    all: () => client.get('/programs'),
    id: id => client.get(`/programs/${id}`),
  }),
  events: () => ({
    all: () => client.get('/events'),
    id: id => client.get(`/events/${id}`),
  }),
  calls: () => ({
    all: () => client.get('/calls'),
    id: id => client.get(`/calls/${id}`),
  }),
  contact: form => client.post('contactform', form),
  fileUpload: files => client.post('/form-control/file-add', files),
});

export default models;
