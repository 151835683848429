exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._2NeHWmBBynklaDuXWJcEeb {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n\n._2wlaCzpu0HQSk0QlQbG-9O {\n  display: inline-block;\n  align-self: flex-start;\n  padding: 0 0 10px;\n  color: #2c3242;\n  cursor: pointer;\n  font-family: \"GT-Walsheim\", \"Open Sans\", Arial, sans-serif;\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 28px;\n  user-select: none;\n}", "", {"version":3,"sources":["/home/runner/work/gin-frontend/gin-frontend/packages/ui/src/common/components/CustomForm/components/RadioWidget/RadioWidget.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,uBAAA;EACA,wBAAA;EACA,4BAAA;CACD;;AAED;EACE,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,2DAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;CACD","file":"RadioWidget.scss","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n\n.label {\n  display: inline-block;\n  align-self: flex-start;\n  padding: 0 0 10px;\n  color: #2c3242;\n  cursor: pointer;\n  font-family: 'GT-Walsheim', 'Open Sans', Arial, sans-serif;\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 28px;\n  user-select: none;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrapper": "_2NeHWmBBynklaDuXWJcEeb",
	"label": "_2wlaCzpu0HQSk0QlQbG-9O"
};