import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import styles from 'components/DatePickerBase/DatePickerBase.scss';

import { Icon } from '@moonshineragency/ui/src';
import { calendar } from '@moonshineragency/icons';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const DatePickerBase = ({ selected, onDateChange }) => (
  <div className={styles.wrapper}>
    <DatePicker
      selected={selected}
      onChange={date => onDateChange(date)}
      showYearDropdown
      dateFormat="dd.MM.yyyy"
      aria-label="Select a date"
    />
    <Icon glyph={calendar} className={styles.icon} />
  </div>
);

DatePickerBase.propTypes = {
  selected: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
};

DatePickerBase.defaultProps = {
  selected: new Date(),
  onDateChange: () => {},
};

export default DatePickerBase;
