import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const urlRegex = /content="([^"]+)"/;
const propertyRegex = /property="([^"]+)"/;

function PageInformation({ title, description, meta }) {
  const metaData =
    meta !== null &&
    meta.map(el => {
      if (!el.match(urlRegex) || !el.match(propertyRegex)) return el;
      const urlMatch = el.match(urlRegex);
      const propertyMatch = el.match(propertyRegex);

      return {
        property: propertyMatch[1],
        content: urlMatch[1],
      };
    });

  return (
    <Helmet title={title} titleTemplate="%s - GIN Austria">
      <meta name="description" content={description} />
      {metaData.length && metaData.map(data => <meta {...data} />)}
    </Helmet>
  );
}

PageInformation.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.string),
};

PageInformation.defaultProps = {
  description: '',
  meta: null,
};

export default PageInformation;
