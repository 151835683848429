import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, Content } from '@moonshineragency/ui/src';
import classNames from 'classnames';

import styles from 'components/SectionHeader/SectionHeader.scss';

class SectionHeader extends Component {
  static propTypes = {
    content: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    noMargin: PropTypes.bool,
  };

  static defaultProps = {
    content: null,
    title: null,
    subtitle: null,
    noMargin: false,
  };

  render() {
    const { title, subtitle, content, noMargin } = this.props;
    return (
      <div
        className={classNames(styles.container, {
          [styles.noMargin]: noMargin,
        })}
        aria-labelledby="section-title section-subtitle"
      >
        <Heading id="section-title" tag="h2" isLarge>
          {title}
        </Heading>
        <Heading id="section-subtitle" tag="h2">
          {subtitle}
        </Heading>
        <Content isResponsive isLarge>
          {content}
        </Content>
      </div>
    );
  }
}

export default SectionHeader;
