import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { arrowRight } from '@moonshineragency/icons';
import { Icon, Button } from '@moonshineragency/ui/src';
import Dropdown from 'pages/App/components/Dropdown/Dropdown';

import styles from 'pages/App/components/ContactForm/ContactForm.scss';
import dropdownStyle from 'pages/App/components/ContactForm/overrideStyles/DropdownStyle.scss';

const ContactForm = ({ data, history }) => {
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  const handleRoleChange = role => {
    setSelectedRole(role);
  };

  const getRegions = () => {
    return data.regions.map(region => ({
      id: region,
      label: region,
      value: region,
    }));
  };

  const getRoles = () => {
    return data.roles.map(role => ({
      id: role.role,
      label: role.role,
      value: role,
    }));
  };

  const getCapitalized = str => {
    return str
      ? str
          .charAt(0)
          .toUpperCase()
          .concat(str.slice(1))
      : '';
  };

  const sendMail = () => {
    history.push('/contact', [
      `I'm${
        selectedRole && selectedRole.article
          ? ' '.concat(selectedRole.article)
          : ''
      } ${getCapitalized(selectedRole.role)} from ${getCapitalized(
        selectedRegion,
      )}`,
    ]);
  };

  return (
    <div className={styles.contactForm} role="form">
      <div className={styles.personList} aria-live="polite">
        <span className={styles.text}>
          {`I'm ${
            selectedRole && selectedRole.article ? selectedRole.article : ''
          }`}
        </span>
        <Dropdown
          items={getRoles()}
          onSelectedChanged={handleRoleChange}
          styles={dropdownStyle}
          smallIcon
          icon={<Icon glyph={arrowRight} />}
          isFooter
          ariaLabel="Select your role"
        />
      </div>
      <div className={styles.personFrom} aria-live="polite">
        <span className={styles.text}>from</span>
        <Dropdown
          items={getRegions()}
          onSelectedChanged={setSelectedRegion}
          styles={dropdownStyle}
          smallIcon
          icon={<Icon glyph={arrowRight} />}
          isFooter
          ariaLabel="Select your region"
        />
      </div>
      <div>
        <Button
          className={styles.contactBtn}
          onClick={sendMail}
          theme="secondary"
          size="normal"
          aria-label="Submit the contact form"
        >
          Contact us
        </Button>
      </div>
    </div>
  );
};

ContactForm.propTypes = {
  data: PropTypes.shape({
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string,
        article: PropTypes.string,
      }),
    ),
    regions: PropTypes.arrayOf(PropTypes.string),
    combinations: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string,
        region: PropTypes.string,
        email: PropTypes.string,
      }),
    ),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(ContactForm);
