import React, { Component } from 'react';
import RadioButton from 'components/RadioButton/RadioButton';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormGroup from 'components/FormGroup/FormGroup';
import styles from 'components/CustomForm/components/RadioWidget/RadioWidget.scss';

class RadioWidget extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    id: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    autofocus: PropTypes.bool,
    options: PropTypes.shape({
      emptyValue: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    insertbefore: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    schema: PropTypes.objectOf(PropTypes.any).isRequired,
    style: PropTypes.objectOf(PropTypes.string),
    wrapperClassName: PropTypes.string,
    grid: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    label: null,
    value: null,
    autofocus: false,
    disabled: false,
    readonly: false,
    options: {
      emptyValue: '',
    },
    insertbefore: null,
    style: null,
    wrapperClassName: null,
    grid: null,
  };

  onChange = value => this.props.onChange(value);

  render() {
    const {
      id,
      options,
      value,
      label,
      required,
      disabled,
      readonly,
      autofocus,
      insertbefore,
      style,
      wrapperClassName,
      grid,
      schema,
    } = this.props;
    const { enumOptions, inline } = options;

    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.inline]: inline,
        })}
      >
        <FormGroup
          label={label}
          id={id}
          tooltip={schema.description}
          required={required}
          aria-describedby={
            schema.description ? `${label}-description` : undefined
          }
        >
          {enumOptions.map((option, i) => {
            const checked = option.value === value;
            const name = Math.random().toString();
            return (
              <RadioButton
                id={`${name}_${i}`}
                label={option.label}
                key={name}
                checked={checked}
                name={name}
                required={required}
                value={option.value}
                disabled={disabled || readonly}
                autoFocus={autofocus && i === 0}
                onChange={this.onChange}
                insertbefore={insertbefore}
                style={style}
                wrapperClassName={wrapperClassName}
                grid={grid}
                aria-labelledby={`${option.label}-label`}
              />
            );
          })}
        </FormGroup>
      </div>
    );
  }
}

export default RadioWidget;
