import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'components/Dropzone/Dropzone';
import FormGroup from 'components/FormGroup/FormGroup';

const acceptedFileTypes = [
  'image/*',
  '.xls',
  '.xlsx',
  '.pdf',
  '.doc',
  '.docx',
  '.ppt',
  '.pptx',
];

const DropzoneWidget = ({
  id,
  required,
  title,
  description,
  onChange,
  onRemove,
  value,
  isDropzoneEmpty,
}) => {
  const handleChange = file => {
    onChange(file, !isDropzoneEmpty);
  };

  const descriptionTitle = `${title}-description`;

  return (
    <FormGroup
      label={title}
      id={id}
      tooltip={description}
      required={required}
      aria-describedby={description ? descriptionTitle : undefined}
    >
      <Dropzone
        files={value}
        onDrop={handleChange}
        onRemove={onRemove}
        multiple
        accept={acceptedFileTypes}
        isDropzoneEmpty={isDropzoneEmpty}
        aria-labelledby={title}
        aria-describedby={description ? descriptionTitle : undefined}
        aria-required={required}
      />
    </FormGroup>
  );
};

DropzoneWidget.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.object),
  isDropzoneEmpty: PropTypes.bool,
};

DropzoneWidget.defaultProps = {
  required: false,
  title: '',
  description: '',
  value: [],
  onRemove: () => {},
  isDropzoneEmpty: true,
};

export default DropzoneWidget;
