import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { app } from 'pages/App/App.state';
import throttle from 'lodash-es/throttle';

/* eslint no-underscore-dangle: ["error", { "allow": ["__PRELOADED_STATE__", "__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] }] */

const reducers = combineReducers({ app });
let preloadedState = window.__PRELOADED_STATE__ || {};
if (window.localStorage) {
  const persistedStore = localStorage.getItem('store');
  if (persistedStore) {
    preloadedState = JSON.parse(persistedStore);
  }
}
const isProduction = false;
const middleware = [promise, thunk];

const composeEnhancers =
  (isProduction ? null : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const appliedMiddleware = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(reducers, preloadedState, appliedMiddleware);
store.subscribe(
  throttle(() => {
    if (window.localStorage) {
      localStorage.setItem('store', JSON.stringify(store.getState()));
    }
  }, 1000),
);
export default store;
