import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Content, Container } from '@moonshineragency/ui/src';

import LegalMenu from 'modules/LegalMenu/LegalMenu';
import ContactForm from 'pages/App/components/ContactForm/ContactForm';
import { getFooterNavigation } from 'pages/App/App.state';

import styles from 'pages/App/components/Footer/Footer.scss';
import legalMenuStyle from 'pages/App/components/Footer/overrideStyles/LegalMenuStyle.scss';

const Footer = ({ footer, onGetFooterNavigation }) => {
  useEffect(() => {
    onGetFooterNavigation();
  }, [onGetFooterNavigation]);

  if (!footer) return null;

  const createItems = () => {
    return footer.links.map(({ key, path }) => ({
      id: key,
      text: key,
      link: path,
    }));
  };
  return (
    <footer className={styles.container} aria-label="Footer">
      <Container className={styles.mainConatiner}>
        <div className={styles.wrapper}>
          <div className={styles.contactInformation}>
            <Content className={styles.footerText} isSmall withParser footer>
              {footer.text}
            </Content>
            {footer && footer.contact && <ContactForm data={footer.contact} />}
          </div>

          <div className={styles.legalMenu}>
            <LegalMenu
              contrast
              items={createItems()}
              styles={legalMenuStyle}
              socialLinks={footer.socialMediaLinks}
            />

            {footer && (
              <div className={styles.partnersWrapper} aria-label="Partners">
                {footer.partners.map(({ title, items }) => (
                  <div className={styles.partnerContainer} key={title}>
                    <h4>{title}</h4>
                    <div className={styles.logosWrapper}>
                      {items &&
                        items.map(({ image, name, slug, website }) => (
                          <a
                            href={website}
                            target="_blank"
                            key={slug}
                            className={styles.partnersLink}
                            rel="noopener noreferrer"
                            aria-label={name}
                          >
                            <img
                              className={styles.partnersImage}
                              src={image && image.src}
                              alt={name}
                            />
                          </a>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export const footerType = PropTypes.shape({
  text: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  socialMediaLinks: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({}),
      name: PropTypes.string,
      slug: PropTypes.string,
      website: PropTypes.string,
    }),
  ),
});

Footer.propTypes = {
  footer: footerType,
  onGetFooterNavigation: PropTypes.func.isRequired,
};

Footer.defaultProps = {
  footer: null,
};

const mapStateToProps = state => ({
  footer: state.app.footer,
});

const mapDispatchToProps = {
  onGetFooterNavigation: getFooterNavigation,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
