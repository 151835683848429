import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select/Select';

const SelectWidget = ({
  id,
  value,
  title,
  required,
  disabled,
  options,
  rawErrors,
  onChange,
  enum_titles: enumTitles,
  enum: enumKey,
}) => {
  const getOptions = () => {
    const allOptions = [];
    enumKey.forEach((option, idx) => {
      allOptions.push({ label: enumTitles[idx], value: option });
    });
    return allOptions;
  };

  const handleChange = ({ value: selectValue }) => {
    onChange(selectValue === '' ? options.emptyValue : selectValue);
  };

  return (
    <Select
      id={id}
      options={getOptions()}
      value={value}
      label={`${title} ${required ? '*' : ''}`}
      onChange={handleChange}
      required={required}
      disabled={disabled}
      isSearchable={options.isSearchable}
      rawErrors={rawErrors}
      aria-required={required}
      aria-disabled={disabled}
      aria-invalid={!!rawErrors}
      aria-describedby={rawErrors ? `${title}-error` : undefined}
    />
  );
};

SelectWidget.propTypes = {
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  enum: PropTypes.arrayOf(PropTypes.string),
  enum_titles: PropTypes.arrayOf(PropTypes.string),
  /* to control how the element should look like,you can write those options in ui:widget schema */
  options: PropTypes.shape({
    emptyValue: PropTypes.string,
    defaultOption: PropTypes.string,
    isSearchable: PropTypes.bool,
  }),
  rawErrors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SelectWidget.defaultProps = {
  title: null,
  rawErrors: null,
  disabled: false,
  enum: null,
  enum_titles: null,
  options: {
    defaultOption: '',
    emptyValue: '',
    help: '',
    isSearchable: false,
  },
  value: null,
  required: false,
};

export default SelectWidget;
