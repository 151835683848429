import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import WithOverridableStyle from 'utils/WithOverridableStyle';

import defaultStyles from 'pages/App/components/Dropdown/Dropdown.scss';

const Dropdown = ({
  items,
  onSelectedChanged,
  icon,
  persistOnBlur,
  styles,
  smallIcon,
  isFooter,
  ariaLabel,
}) => {
  const [showList, setShowList] = useState(false);
  const [selectionIndex, setSelectionIndex] = useState(
    items.length > 0 ? 0 : -1,
  );
  // Hover index is used as an indicator of currently 'hovered' element in the list for usability purposes.
  const [hoverIndex, setHoverIndex] = useState(items.length > 0 ? 0 : -1);
  const [selected, setSelected] = useState(
    items.length > 0
      ? items[0]
      : {
          id: 'null',
          label: '-',
          value: null,
        },
  );

  const list = useRef(null);

  // Used to calculate the scroll position - needed for when using arrow keys to scroll.
  useLayoutEffect(() => {
    const { current } = list;
    if (current && items.length > 0 && hoverIndex > 0) {
      current.scrollTop =
        (hoverIndex - 1) * (current.scrollHeight / items.length);
    } else if (!showList) {
      setHoverIndex(selectionIndex);
    }
  }, [hoverIndex, items.length, selectionIndex, showList]);

  useEffect(() => {
    if (selected && onSelectedChanged) onSelectedChanged(selected.value);
  }, [onSelectedChanged, selected]);

  useEffect(() => {
    setSelectionIndex(items.length > 0 ? 0 : -1);
    setHoverIndex(items.length > 0 ? 0 : -1);
  }, [items.length]);

  const handleItemClick = (item, index) => {
    setSelected(item);
    setSelectionIndex(index);
    setHoverIndex(index);
    setShowList(false);
  };

  const handleItemFocus = index => {
    setHoverIndex(index);
  };

  const handleMenuClick = () => {
    setShowList(prev => !prev);
  };

  // User can navigate the dropdown list with arrow keys and select with enter.
  const handleKeyDown = event => {
    switch (event.keyCode) {
      case 13:
        event.preventDefault();
        if (showList === true && hoverIndex > -1) {
          setSelected(items[hoverIndex]);
          setShowList(false);
          setSelectionIndex(hoverIndex);
        } else {
          setShowList(true);
        }
        break;
      case 38:
        event.preventDefault();
        if (hoverIndex > 0) setHoverIndex(i => i - 1);
        break;
      case 40:
        event.preventDefault();
        if (hoverIndex < items.length - 1) setHoverIndex(i => i + 1);
        break;
      default:
        break;
    }
  };

  // Used to determine if component's root element contains focused element.
  const containsFocused = blurEvent => {
    const { relatedTarget, currentTarget } = blurEvent;
    if (relatedTarget) {
      let element = relatedTarget.parentNode;
      while (element !== null) {
        if (element === currentTarget) return true;
        element = element.parentNode;
      }
    }
    return false;
  };

  const handleBlur = event => {
    if (!persistOnBlur) {
      if (!containsFocused(event)) setShowList(false);
    }
  };

  return (
    <div onBlur={handleBlur} className={styles.dropdown}>
      <div
        onKeyDown={handleKeyDown}
        onClick={handleMenuClick}
        className={classNames(styles.menu, { [styles.footerMenu]: isFooter })}
        aria-controls="comboboxList"
        aria-haspopup="listbox"
        aria-expanded={showList}
        role="combobox"
        tabIndex="0"
        aria-label={ariaLabel || 'Select an option'}
      >
        <div className={styles.selected}>{selected && selected.label}</div>
        <div
          aria-hidden="true"
          className={classNames(styles.icon, {
            [styles.dropdownSmallIcon]: smallIcon,
          })}
        >
          {icon}
        </div>
      </div>
      {showList && (
        <div
          className={styles.list}
          ref={list}
          role="listbox"
          id="comboboxList"
          aria-activedescendant={
            items[hoverIndex] ? `item-${items[hoverIndex].id}` : ''
          }
        >
          {items.map((item, index) => (
            <div
              onClick={() => handleItemClick(item, index)}
              onKeyDown={handleKeyDown}
              className={classNames(styles.option, {
                [styles.optionSelected]: index === selectionIndex,
                [styles.hovered]: index === hoverIndex,
              })}
              role="option"
              aria-selected={selected.label === item.label}
              id={`item-${item.id}`}
              key={item.id}
              onFocus={() => handleItemFocus(index)}
              tabIndex="0"
              aria-label={item.label}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  onSelectedChanged: PropTypes.func,
  icon: PropTypes.node,
  persistOnBlur: PropTypes.bool,
  smallIcon: PropTypes.bool,
  isFooter: PropTypes.bool,
  styles: PropTypes.shape({}),
  ariaLabel: PropTypes.string,
};

Dropdown.defaultProps = {
  onSelectedChanged: () => {},
  icon: null,
  smallIcon: true,
  persistOnBlur: false,
  isFooter: false,
  styles: {},
  ariaLabel: 'Select an option',
};

export default WithOverridableStyle(Dropdown, defaultStyles);
