import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import { usePrevious } from 'utils/customHooks';
import BaseInput from 'components/CustomForm/components/BaseInput/BaseInput';
import SelectWidget from 'components/CustomForm/components/SelectWidget/SelectWidget';
import TextareaWidget from 'components/CustomForm/components/TextareaWidget/TextareaWidget';
import CheckboxWidget from 'components/CustomForm/components/CheckboxWidget/CheckboxWidget';
import DateWidget from 'components/CustomForm/components/DateWidget/DateWidget';
import RadioWidget from 'components/CustomForm/components/RadioWidget/RadioWidget';
import FormButtons from 'components/CustomForm/components/FormButtons/FormButtons';
import DropzoneWidget from 'components/CustomForm/components/DropzoneWidget/DropzoneWidget';

import styles from 'components/CustomForm/CustomForm.scss';
import CheckboxGroupWidget from './components/CheckboxGroupWidget/CheckboxGroupWidget';

const CustomForm = ({
  value,
  files,
  buttonText,
  className,
  onSubmit,
  schema,
  formErrors,
  onChange,
  steps,
  onStepChange,
  onRemoveFile,
  isClickDisabled,
  onChangeDisabled,
  isLoading,
}) => {
  const [propArray, setPropArray] = useState(null);
  const [fields, setFields] = useState(null);
  const [isDropzoneEmpty, setIsDropzoneEmpty] = useState(true);
  const prevSchema = usePrevious(schema);
  const prevSteps = usePrevious(steps);

  const handleChange = val => {
    if (steps)
      onChange({
        ...value,
        [steps.stepId]: value[steps.stepId]
          ? [{ ...value[steps.stepId][0], ...val }]
          : [{ ...val }],
      });
    else onChange({ ...value, val });
  };

  const makeArrayFromProperties = properties =>
    Object.keys(properties)
      .map(fieldKey => ({
        ...properties[fieldKey],
        key: fieldKey,
      }))
      .sort((a, b) => a.propertyOrder - b.propertyOrder);

  const matchKeyOfErrors = key =>
    formErrors[Object.keys(formErrors).find(item => item.includes(key))];

  const isFieldRequired = field => {
    const currentStepItems = _get(propArray[steps.currentStep], 'items');
    const requiredFields = _get(currentStepItems, 'required');

    return steps && currentStepItems && requiredFields
      ? currentStepItems.required.some(item => item === field.key)
      : schema.required.some(item => item === field.key);
  };

  const getField = field => {
    const { placeholder } = field.attr;
    const allProps = {
      ...field,
      placeholder: placeholder || '',
      id: field.key,
      onChange: e => handleChange({ [field.key]: e }),
      value: value[steps.stepId]
        ? _get(value[steps.stepId][0], field.key)
        : value[field.key] || undefined,
      required: isFieldRequired(field),
      rawErrors: formErrors && matchKeyOfErrors(field.key),
    };

    switch (field.widget) {
      case 'textarea': {
        return <TextareaWidget {...allProps} />;
      }

      case 'checkbox': {
        return <CheckboxWidget {...allProps} />;
      }

      case 'select': {
        return <SelectWidget {...allProps} />;
      }

      case 'date': {
        return <DateWidget {...allProps} />;
      }

      case 'radio': {
        return <RadioWidget {...allProps} />;
      }

      case 'checkboxGroup': {
        return <CheckboxGroupWidget {...allProps} />;
      }

      case 'file': {
        return (
          <DropzoneWidget
            {...allProps}
            onChange={(e, isEmpty) => {
              setIsDropzoneEmpty(isEmpty);
              onChange({ key: field.key, value: e }, true);
            }}
            onRemove={e => {
              setIsDropzoneEmpty(true);
              onRemoveFile({ key: field.key, value: e });
            }}
            value={files[field.key]}
            isDropzoneEmpty={isDropzoneEmpty}
          />
        );
      }

      default: {
        return <BaseInput {...allProps} type="text" />;
      }
    }
  };

  const updateSteps = (e, direction) => {
    e.preventDefault();

    if (direction === -1) {
      onChangeDisabled(false);
    }

    const updatedSteps = {
      ...steps,
      currentStep: steps.currentStep + direction,
    };
    window.scrollTo(0, 0);
    onStepChange(updatedSteps);
  };

  // Set fields for the form, decide if the form has fieldsets and split it into pages
  useEffect(() => {
    if (schema !== prevSchema) {
      const fieldArray = makeArrayFromProperties(schema.properties).filter(
        f => f.key !== 'formRuntimeData' && f.key !== 'formRuntimeDataToken',
      );
      if (fieldArray.some(field => field.widget === 'fieldset')) {
        if (!steps) {
          const stepsObj = {
            allSteps: fieldArray.length - 1,
            currentStep: 0,
            stepId: fieldArray[0].key,
            stepTitles: fieldArray.map(field => ({ title: field.title })),
          };
          onStepChange(stepsObj);
        }
        setPropArray(fieldArray);
      } else {
        setFields(fieldArray);
      }
    }
  }, [value, schema, steps, prevSchema, onStepChange]);

  // Set fields for the current step
  useEffect(() => {
    if (
      steps &&
      propArray &&
      _get(prevSteps, 'currentStep') !== _get(steps, 'currentStep')
    ) {
      const currentStepFields = propArray[steps.currentStep];
      const updatedSteps = { ...steps, stepId: currentStepFields.key };

      setFields(
        currentStepFields.items
          ? makeArrayFromProperties(currentStepFields.items.properties)
          : [currentStepFields],
      );

      onStepChange(updatedSteps);
    }
  }, [steps, propArray, prevSteps, onStepChange]);

  return (
    <div className={className}>
      <form onSubmit={onSubmit} aria-label="Custom Form">
        <div className={styles.fieldsWrapper}>
          {fields && fields.map(field => getField(field))}
        </div>

        <FormButtons
          steps={steps}
          formErrors={formErrors}
          submitButtonText={buttonText}
          onUpdateSteps={updateSteps}
          isLoading={isLoading}
          disabled={
            (fields &&
              fields.some(field => field.widget === 'file') &&
              isDropzoneEmpty) ||
            isClickDisabled
          }
        />
      </form>
    </div>
  );
};

CustomForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttonText: PropTypes.string,
  buttonWrapperClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  /* initial state of all form elements */
  value: PropTypes.objectOf(PropTypes.any),
  steps: PropTypes.shape({}),
  files: PropTypes.shape({}),
  schema: PropTypes.objectOf(PropTypes.any),
  formErrors: PropTypes.shape({}),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onStepChange: PropTypes.func,
  onRemoveFile: PropTypes.func,
  isClickDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChangeDisabled: PropTypes.func,
};

CustomForm.defaultProps = {
  schema: null,
  id: null,
  className: null,
  buttonText: 'submit',
  buttonWrapperClassName: null,
  buttonClassName: null,
  isLoading: false,
  value: null,
  steps: {},
  files: {},
  formErrors: {},
  onChange: () => {},
  onSubmit: () => {},
  onStepChange: () => {},
  onRemoveFile: () => {},
  isClickDisabled: false,
  onChangeDisabled: () => {},
};

export default CustomForm;
