import React, { Component } from 'react';
import ReactDropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import Heading from 'components/Heading/Heading';
import { Icon } from '@moonshineragency/ui/src';
import { plus } from '@moonshineragency/icons';

import styles from 'components/Dropzone/Dropzone.scss';

class Dropzone extends Component {
  static propTypes = {
    // Maximum file size (in bytes)
    maxSize: PropTypes.number,
    // Minimum file size (in bytes)
    minSize: PropTypes.number,
    // Allow dropping multiple files
    multiple: PropTypes.bool,
    // onDropAccepted callback
    onDropAccepted: PropTypes.func,
    // onDropRejected callback
    onDropRejected: PropTypes.func,
    // onDragEnter callback
    onDragEnter: PropTypes.func,
    onDrop: PropTypes.func,
    onRemove: PropTypes.func,
    accept: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    files: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    accept: 'image/jpeg',
    files: null,
    maxSize: 10 ** 1000,
    minSize: 0,
    multiple: true,
    onDrop: () => {},
    onDropAccepted: () => {},
    onDropRejected: () => {},
    onDragEnter: () => {},
    onRemove: () => {},
  };

  onPreviewDrop = (acceptedFiles, rejectedFiles) => {
    this.props.onDrop(acceptedFiles, rejectedFiles);
  };

  render() {
    const {
      maxSize,
      minSize,
      multiple,
      onDropAccepted,
      onDropRejected,
      onDragEnter,
      onRemove,
      accept,
      files,
    } = this.props;

    return (
      <div className={styles.filesHolder} aria-live="polite">
        <div className={styles.files} role="list">
          {files &&
            files.map((file, index) => (
              <button
                className={styles.file}
                key={index.toString()}
                onClick={() => onRemove(file.name)}
                type="button"
                aria-label={`Remove file ${file.name}`}
                role="listitem"
              >
                <p className={styles.removeIcon}>-</p>
                <p>{file.name}</p>
              </button>
            ))}
        </div>
        <ReactDropzone
          className={styles.inputWrapper}
          accept={accept}
          onDrop={this.onPreviewDrop}
          maxSize={maxSize}
          minSize={minSize}
          multiple={multiple}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          onDragEnter={onDragEnter}
          aria-label="Drop files here or click to upload"
        >
          <div className={styles.fileInput}>
            <Icon glyph={plus} className={styles.icon} />
            <Heading tag="h3" className={styles.heading}>
              upload files
            </Heading>
          </div>
        </ReactDropzone>
      </div>
    );
  }
}

export default Dropzone;
