import React, { PureComponent, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'components/Label/Label.scss';

class Label extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    tooltip: PropTypes.node,
    item: PropTypes.node,
    inlineGroup: PropTypes.bool,
    isRequired: PropTypes.bool,
    dynamicProps: PropTypes.shape({
      isDynamic: PropTypes.bool,
      isFocused: PropTypes.bool,
      hasValue: PropTypes.bool,
      hasIconPrefix: PropTypes.bool,
      isDisabled: PropTypes.bool,
    }),
    requiredSuffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  };

  static defaultProps = {
    id: '',
    label: '',
    tooltip: null,
    item: null,
    inlineGroup: false,
    isRequired: false,
    dynamicProps: {},
    requiredSuffix: ' *',
  };

  handleLabelMouseDown = event => {
    event.preventDefault();

    if (this.input) {
      this.input.focus();
    }
  };

  focus() {
    if (this.input && document.activeElement !== this.input) {
      this.input.focus();
    }
  }

  render() {
    const {
      id,
      label,
      tooltip,
      item,
      inlineGroup,
      isRequired,
      dynamicProps: {
        isDynamic,
        isFocused,
        hasValue,
        hasIconPrefix,
        isDisabled,
      },
      requiredSuffix,
    } = this.props;

    const suffixElement = (
      <span className={styles.requiredSuffix}>{requiredSuffix}</span>
    );

    return (
      <Fragment>
        {label && (
          <label
            className={classNames(styles.label, {
              [styles.isDisabled]: isDisabled,
              [styles.dynamicLabel]: isDynamic,
              [styles.hasIconPrefix]: hasIconPrefix,
              [styles.isFocused]: isFocused,
              [styles.hasValue]: hasValue,
              [styles.inline]: inlineGroup,
            })}
            htmlFor={id}
            onMouseDown={this.handleLabelMouseDown}
            aria-labelledby={id}
            aria-required={isRequired}
          >
            <div className={styles.labelText}>
              {label}
              {isRequired && suffixElement}
            </div>
            {tooltip && <div className={styles.tooltip}>{tooltip}</div>}

            {item && <div className={styles.item}>{item}</div>}
          </label>
        )}
      </Fragment>
    );
  }
}

export default Label;
