import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectInput, { components } from 'react-select';
import { check, edit } from '@moonshineragency/icons';

import Icon from 'components/Icon/Icon';
import Label from 'components/Label/Label';

import styles from 'components/Select/Select.scss';

const SearchIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon glyph={edit} />
  </components.DropdownIndicator>
);

const CustomOption = ({
  // eslint-disable-next-line react/prop-types
  innerProps,
  // eslint-disable-next-line react/prop-types
  isDisabled,
  // eslint-disable-next-line react/prop-types
  children,
  // eslint-disable-next-line react/prop-types
  isSelected,
}) =>
  !isDisabled ? (
    <div
      {...innerProps}
      className={classNames('selectInput__option', {
        'selectInput__option--is-selected': isSelected,
      })}
      role="option"
      aria-selected={isSelected}
    >
      {children}
      {isSelected && <Icon glyph={check} className={styles.check} />}
    </div>
  ) : null;

class Select extends Component {
  static propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    required: PropTypes.bool, // TODO: change to "required" (also in Input)
    isSearchable: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rawErrors: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    id: '',
    label: null,
    onChange: () => {},
    value: null,
    required: false,
    isSearchable: false,
    disabled: false,
    options: [{}],
    rawErrors: null,
  };

  handleChange = value => {
    this.props.onChange(value);
  };

  selectedValue = value => {
    if (value === null) {
      return null;
    }
    return this.props.options.find(item => item.value === value);
  };

  renderHint = () => {
    if (this.props.rawErrors) {
      return (
        <p className={styles.hint} id={`${this.props.id}-error`}>
          {this.props.rawErrors.toString()}
        </p>
      );
    }

    return null;
  };

  render() {
    const {
      id,
      value,
      options,
      label,
      isSearchable,
      required,
      disabled,
    } = this.props;
    const selectedOption = this.selectedValue(value);

    const customElements = { Option: CustomOption };
    if (isSearchable) {
      customElements.DropdownIndicator = SearchIndicator;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.select}>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <Label label={label} id={id} />
          {this.renderHint()}
          <SelectInput
            id={id}
            disabled={disabled}
            components={customElements}
            options={options}
            value={selectedOption}
            onChange={this.handleChange}
            className={styles.selectInput}
            classNamePrefix="selectInput"
            required={required}
            aria-describedby={this.props.rawErrors ? `${id}-error` : undefined}
            aria-required={required}
          />
        </div>
      </div>
    );
  }
}

export default Select;
