import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button/Button';

import styles from 'components/CustomForm/components/FormButtons/FormButtons.scss';

const FormButtons = ({
  steps,
  formErrors,
  onUpdateSteps,
  submitButtonText,
  disabled,
  isLoading,
}) => {
  const shouldSubmitShow = () => !steps || steps.currentStep === steps.allSteps;

  return (
    <React.Fragment>
      {steps && (
        <div
          className={styles.navigationButtons}
          role="navigation"
          aria-label="Form Navigation"
        >
          {steps.currentStep === 0 ? (
            <div />
          ) : (
            <Button
              rounded
              theme="unset"
              view="link"
              onClick={e => onUpdateSteps(e, -1)}
              aria-label="Previous Step"
            >
              Previous
            </Button>
          )}
          {!shouldSubmitShow() && (
            <Button
              rounded
              theme="unset"
              onClick={e => onUpdateSteps(e, 1)}
              view="link"
              disabled={disabled}
              aria-label="Next Step"
            >
              Next
            </Button>
          )}

          {steps && shouldSubmitShow() && (
            <div className={styles.button}>
              <Button
                rounded
                type="submit"
                theme="secondary"
                view="link"
                loading={isLoading}
                aria-label="Submit Form"
              >
                {submitButtonText}
              </Button>
            </div>
          )}
        </div>
      )}

      {!steps && shouldSubmitShow() && (
        <div className={styles.button}>
          <Button
            rounded
            type="submit"
            theme="secondary"
            loading={isLoading}
            aria-label="Submit Form"
          >
            {submitButtonText}
          </Button>
        </div>
      )}

      {shouldSubmitShow() && formErrors && (
        <p className={styles.errorMessage} role="alert" aria-live="assertive">
          Error. Please review the form and submit again.
        </p>
      )}
    </React.Fragment>
  );
};

FormButtons.propTypes = {
  steps: PropTypes.shape({
    allSteps: PropTypes.number,
    currentStep: PropTypes.number,
    stepId: PropTypes.string,
  }),
  formErrors: PropTypes.shape({}),
  onUpdateSteps: PropTypes.func,
  submitButtonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

FormButtons.defaultProps = {
  steps: null,
  formErrors: null,
  onUpdateSteps: () => {},
  disabled: false,
  isLoading: false,
};

export default FormButtons;
