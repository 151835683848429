import React, { Component } from 'react';
import track from 'react-tracking';
import { Heading, Container } from '@moonshineragency/ui/src';

import PageInformation from 'components/PageInformation/PageInformation';
import styles from './NotFound.scss';

@track({ page: 'NotFound' })
class NotFound extends Component {
  render() {
    return (
      <Container className={styles.container}>
        <PageInformation title="Not found" />
        <Heading className={styles.pageInfoHeading}>Page was not found</Heading>
      </Container>
    );
  }
}

export default NotFound;
