import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Button } from '@moonshineragency/ui/src';

import { updateCookieConsent } from 'pages/App/App.state';

import styles from 'pages/App/components/CookieBanner/CookieBanner.scss';

const CookieBanner = ({ onUpdateCookieConsent, isCookieConsentAccepted }) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.hasChosen]: isCookieConsentAccepted !== null,
      })}
      role="dialog"
      aria-live="polite"
      aria-describedby="cookie-banner-description"
      aria-labelledby="cookie-banner-title"
    >
      <span id="cookie-banner-title" className={styles.dNone}>
        Cookie Banner
      </span>
      <p id="cookie-banner-description" className={styles.text}>
        This website uses cookies to ensure you get the best experience on our
        website. Find out more on{' '}
        <Link
          to="/Privacy"
          tabIndex={isCookieConsentAccepted !== null ? -1 : 0}
        >
          https://www.gin-austria.com/Privacy
        </Link>
        .
      </p>
      <div className={styles.actionButtons}>
        <Button
          onClick={() => onUpdateCookieConsent(false)}
          theme="secondary"
          view="link"
          aria-label="Deny cookie usage"
        >
          Deny
        </Button>
        <Button
          onClick={() => onUpdateCookieConsent(true)}
          theme="secondary"
          aria-label="Accept cookie usage"
        >
          Accept
        </Button>
      </div>
    </div>
  );
};

CookieBanner.propTypes = {
  onUpdateCookieConsent: PropTypes.func.isRequired,
  isCookieConsentAccepted: PropTypes.bool,
};

CookieBanner.defaultProps = {
  isCookieConsentAccepted: null,
};

const mapStateToProps = state => ({
  isCookieConsentAccepted: state.app.isCookieConsentAccepted,
});

const mapDispatchToProps = {
  onUpdateCookieConsent: updateCookieConsent,
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieBanner);
