import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'components/Input/Input.scss';
import TextareaAutosize from 'react-autosize-textarea';
import * as Icons from '@moonshineragency/icons';
import Icon from 'components/Icon/Icon';

import Label from 'components/Label/Label';

export default class Input extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    prefixClassName: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.oneOf([
      'text',
      'number',
      'email',
      'date',
      'search',
      'tel',
      'url',
      'password',
      'textarea',
      'hidden',
      'multiline',
    ]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    large: PropTypes.bool,
    small: PropTypes.bool,
    placeholder: PropTypes.string,
    prefix: PropTypes.string,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    hint: PropTypes.string,
    children: PropTypes.node,
    status: PropTypes.oneOf(['normal', 'success', 'error', 'warning']),
    theme: PropTypes.oneOf(['default', 'contrast']),
    iconAfter: PropTypes.node,
    autoFocus: PropTypes.bool,
    htmlAutoFocus: PropTypes.bool,
    tabIndex: PropTypes.number,
    spellcheck: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyPress: PropTypes.func,
    maxLength: PropTypes.number,
    onError: PropTypes.func,
    extendedOptionIcon: PropTypes.string,
    extendedOptionHandler: PropTypes.func,
    // just for textarea
    // Called whenever the textarea resizes
    onResize: PropTypes.func,
    // Minimum number of visible rows
    rows: PropTypes.number,
    // Maximum number of visible rows
    maxRows: PropTypes.number,
    // TODO: refactor prefix/iconPrefix
    iconSuffix: PropTypes.shape({ viewBox: PropTypes.string }),
    iconPrefix: PropTypes.shape({ viewBox: PropTypes.string }),
  };

  static defaultProps = {
    onChange: () => {},
    placeholder: '',
    iconSuffix: null,
    iconPrefix: null,
    children: null,
    theme: 'default',
    prefix: '',
    disabled: false,
    readonly: false,
    hint: '',
    type: 'text',
    status: 'normal',
    iconAfter: null,
    spellcheck: false,
    small: false,
    className: '',
    maxLength: 0,
    value: '',
    label: '',
    autoFocus: false,
    htmlAutoFocus: false,
    tabIndex: 0,
    name: '',
    isRequired: false,
    large: false,
    inputClassName: '',
    wrapperClassName: '',
    prefixClassName: '',
    id: null,
    onError: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onKeyUp: () => {},
    onKeyDown: () => {},
    onKeyPress: () => {},
    extendedOptionHandler: () => {},
    extendedOptionIcon: null,
    rows: 1,
    maxRows: 10 * 100,
    onResize: () => {},
  };

  positions = {
    left: {
      default: 16,
      prefixed: 18,
    },
    top: {
      default: 0,
      prefixed: 0,
    },
  };

  state = {
    isFocused: false,
    maxLengthLimitation: false,
  };

  componentDidMount() {
    this.autoFocus();
  }

  componentDidUpdate() {
    this.autoFocus();
  }

  setInput = element => {
    this.input = element;
  };

  handleBlur = event => {
    if (this.isAutoFocus()) {
      event.preventDefault();
      event.target.focus();

      return;
    }

    this.setState({ isFocused: false });

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  handleFocus = event => {
    this.setState({ isFocused: true });

    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  };

  handleChange = event => {
    this.props.onChange(event.target.value, event);
  };

  handleError = error => {
    this.props.onError(error);
  };

  isAutoFocus() {
    return Boolean(this.props.autoFocus) && !this.props.disabled;
  }

  autoFocus() {
    if (this.isAutoFocus() && this.input) {
      if (document.activeElement !== this.input) {
        this.input.focus();
      }
    }
  }

  focus() {
    if (this.input && document.activeElement !== this.input) {
      this.input.focus();
    }
  }

  blur() {
    if (this.input) {
      this.input.blur();
    }
  }

  renderCounter() {
    const { maxLengthLimitation } = this.state;
    const { maxLength, value } = this.props;
    if (!maxLength) {
      return null;
    }

    if (value) {
      if (value.length > maxLength) {
        this.setState({ maxLengthLimitation: true });
      } else {
        this.setState({ maxLengthLimitation: false });
      }
    }

    return (
      <div className={styles.counter}>
        <div
          className={classNames(styles.counterDisplay, {
            [styles.maxLengthLimitation]: maxLengthLimitation,
          })}
        >
          {value ? value.length : 0}
          {'/'}
          {maxLength}
        </div>
      </div>
    );
  }

  renderHint() {
    const { hint, id } = this.props;

    if (!hint) {
      return null;
    }

    return (
      <p id={`${id}-hint`} className={styles.hint}>
        {hint}
      </p>
    );
  }

  renderPrefix() {
    const { prefix, iconPrefix, id } = this.props;
    const className = classNames(styles.prefix, this.props.prefixClassName);

    if (prefix) {
      return (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label
          htmlFor={id}
          className={className}
          onMouseDown={this.handleLabelMouseDown}
        >
          {prefix}
        </label>
      );
    }
    if (iconPrefix) {
      return (
        <Icon
          glyph={iconPrefix}
          className={classNames(styles.iconPrefix, this.props.prefixClassName)}
        />
      );
    }
    return false;
  }

  renderSuffix() {
    const { status, iconSuffix } = this.props;

    const mappedElements = {
      success: Icons.check,
    };

    if (!mappedElements[status] && !iconSuffix) return null;
    let glyph = iconSuffix;

    if (!iconSuffix) {
      glyph = mappedElements[status];
    }

    return <Icon className={styles.suffix} glyph={glyph} />;
  }

  renderExtendedOption() {
    const { extendedOptionIcon, extendedOptionHandler } = this.props;
    if (!extendedOptionIcon || !extendedOptionHandler) return null;

    return (
      <div role="presentation" onClick={extendedOptionHandler}>
        <Icon
          className={styles.extendedOption}
          glyph={Icons[extendedOptionIcon]}
        />
      </div>
    );
  }

  renderInput() {
    const {
      id,
      name,
      type,
      value,
      disabled,
      readonly,
      tabIndex,
      placeholder,
      children,
      htmlAutoFocus,
      onKeyUp,
      onKeyDown,
      onKeyPress,
      spellcheck,
      hint,
      status,
    } = this.props;

    const ariaProps = {
      'aria-invalid': status === 'error',
      'aria-describedby': hint ? `${id}-hint` : undefined,
    };

    const props = {
      className: classNames(styles.input, this.props.inputClassName),
      disabled,
      readOnly: readonly,
      id,
      name,
      placeholder,
      type,
      value,
      tabIndex,
      autoFocus: htmlAutoFocus,
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      onError: this.handleError,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      ...ariaProps,
    };

    if (children) {
      return children;
    }
    if (type === 'textarea') {
      const { rows, maxRows, onResize } = this.props;
      return (
        <TextareaAutosize
          {...props}
          value={value}
          spellCheck={spellcheck ? 'true' : 'false'}
          rows={rows}
          maxRows={maxRows}
          onResize={onResize}
          // eslint-disable-next-line
          innerRef={ref => (this.setInput = ref)}
        />
      );
    }

    // hide "LastPass" icon
    const lpignore = type !== 'password' ? 'true' : 'false';

    return (
      <input
        ref={this.setInput}
        {...props}
        data-lpignore={lpignore}
        spellCheck={spellcheck ? 'true' : 'false'}
      />
    );
  }

  renderlabel = () => {
    const { isFocused } = this.state;
    const {
      value,
      autoFocus,
      name,
      readonly,
      label,
      isRequired,
      iconPrefix,
      disabled,
    } = this.props;
    if (!label) return null;

    return (
      // eslint-disable-next-line jsx-a11y/label-has-for
      <Label
        id={name}
        label={label}
        isRequired={isRequired}
        dynamicProps={{
          isDynamic: false,
          isFocused: isFocused || autoFocus,
          // toString.length for int fields
          hasValue: !!value && !!`${value}`.length,
          hasIconPrefix: !!iconPrefix,
          isDisabled: readonly || disabled,
        }}
      />
    );
  };

  render() {
    const {
      props: {
        value,
        disabled,
        readonly,
        status,
        large,
        small,
        maxLength,
        label,
        iconPrefix,
        hint,
        theme,
        iconSuffix,
      },
      state: { isFocused, maxLengthLimitation },
    } = this;

    const containerClassNames = [
      styles.container,
      this.props.className,
      styles[theme],
      {
        [styles[status]]: status,
        [styles.filled]: value,
        [styles.focused]: isFocused,
        [styles.maxLengthLimitation]: maxLengthLimitation,
        [styles.isDisabled]: readonly || disabled,
        [styles.large]: large,
        [styles.hasHint]: hint,
        [styles.small]: small,
        [styles.counterLabel]: maxLength,
      },
    ];

    if (label) {
      containerClassNames.push(styles.labeled);
    }

    const wrapperClassName = classNames(
      styles.inputWrapper,
      this.props.wrapperClassName,
      {
        [styles.inputIconPrefix]: iconPrefix,
        [styles.inputIconSuffix]: iconSuffix,
      },
    );

    return (
      <div className={classNames(...containerClassNames)}>
        {this.renderPrefix()}
        {this.renderlabel()}
        {this.renderHint()}
        <div className={wrapperClassName}>
          {this.renderInput()}
          {this.renderExtendedOption()}
        </div>
        {this.renderSuffix()}
        {this.renderCounter()}
      </div>
    );
  }
}
