import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from 'components/Skeleton/Skeleton.scss';

function Skeleton({ height, width, count, className }) {
  const styleHeight = height ? { height: `${height}px` } : {};
  return (
    <Fragment>
      {Array.from(Array(count).keys()).map(index => (
        <div
          key={index}
          className={classNames(styles.container, className)}
          style={{ ...styleHeight, width: `${width}px` }}
        />
      ))}
    </Fragment>
  );
}
Skeleton.propTypes = {
  /** width of component */
  height: PropTypes.number,
  /** height of skeleton */
  width: PropTypes.number,
  /** number of skeletons that will get generated */
  count: PropTypes.number,
  /** classname */
  className: PropTypes.string,
};
Skeleton.defaultProps = {
  height: null,
  width: null,
  className: null,
  count: 1,
};
export default Skeleton;
