import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CheckboxWidget from 'components/CustomForm/components/CheckboxWidget/CheckboxWidget';
import Label from 'components/Label/Label';

import styles from 'components/CustomForm/components/CheckboxGroupWidget/CheckboxGroupWidget.scss';

const CheckboxGroupWidget = ({
  items,
  title,
  value,
  onChange,
  rawErrors,
  required,
}) => {
  const [checkboxes, setCheckboxes] = useState(null);

  const isValueTrue = item => value && value.indexOf(item) > -1;

  useEffect(() => {
    if (items) {
      const { enum: enumKey, enum_titles: enumTitles } = items;
      const options = [];
      enumKey.forEach((option, idx) => {
        options.push({ label: enumTitles[idx], value: option });
      });
      setCheckboxes(options);
    }
  }, [items, value]);

  const handleChange = (e, checkValue) => {
    let updatedValue;
    if (e) {
      updatedValue = [...value, checkValue];
    } else {
      updatedValue = [
        ...value.slice(0, value.indexOf(checkValue)),
        ...value.slice(value.indexOf(checkValue) + 1),
      ];
    }
    onChange(updatedValue);
  };

  const renderHint = () => {
    if (rawErrors) {
      return <p className={styles.hint}>{rawErrors.toString()}</p>;
    }

    return null;
  };

  return (
    checkboxes && (
      <div className={styles.container}>
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <Label label={`${title} ${required ? '*' : ''}`} id={title} />
        {renderHint()}
        <div
          className={styles.checkboxesWrapper}
          role="group"
          aria-labelledby={title}
        >
          {checkboxes.map(checkbox => (
            <CheckboxWidget
              key={checkbox.value}
              id={checkbox.value}
              title={checkbox.label}
              checked={isValueTrue(checkbox.value)}
              onChange={e => handleChange(e, checkbox.value)}
              value={isValueTrue(checkbox.value)}
            />
          ))}
        </div>
      </div>
    )
  );
};

CheckboxGroupWidget.propTypes = {
  items: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  rawErrors: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
};

CheckboxGroupWidget.defaultProps = {
  value: [],
  rawErrors: null,
  required: false,
};

export default CheckboxGroupWidget;
