import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Icon } from '@moonshineragency/ui/src';
import * as IconList from '@moonshineragency/icons';
import WithOverridableStyle from 'utils/WithOverridableStyle';

import defaultStyle from 'modules/LegalMenu/LegalMenu.scss';

const LegalMenu = ({ items, contrast, socialLinks, onLinkClick, styles }) => (
  <div
    className={classNames(styles.container, {
      [styles.contrast]: contrast,
    })}
  >
    <nav aria-label="Legal menu">
      <ul className={styles.navList}>
        {items.map(({ link, text, id }) => (
          <li key={id} className={defaultStyle.linkList}>
            <Link className={styles.link} to={link} onClick={onLinkClick}>
              {text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
    <div className={styles.footerEndWrapper}>
      {socialLinks &&
        socialLinks.map(({ key, path }) => (
          <a
            key={`socialLink-${key}`}
            href={path}
            target="_blank"
            className={styles.socialLink}
            rel="noopener noreferrer"
            aria-label={key}
          >
            <Icon glyph={IconList[key]} aria-hidden="true" />
          </a>
        ))}
      <p className={styles.title}>© GIN {dayjs().year()}</p>
    </div>
  </div>
);

LegalMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      link: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
  contrast: PropTypes.bool,
  onLinkClick: PropTypes.func,
  styles: PropTypes.shape({}),
};

LegalMenu.defaultProps = {
  contrast: false,
  onLinkClick: () => {},
  styles: {},
};

export default WithOverridableStyle(LegalMenu, defaultStyle);
