import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from 'components/FormGroup/FormGroup.scss';
import Label from 'components/Label/Label';
import CustomToolTip from 'components/Label/components/CustomToolTip/CustomToolTip';

class FormGroup extends Component {
  static propTypes = {
    /** id for label and input element */
    id: PropTypes.string,
    children: PropTypes.node.isRequired,
    /** label text for the input element */
    label: PropTypes.string,
    /** text for the tooltip element */
    tooltip: PropTypes.string,
    inlineGroup: PropTypes.bool,
    required: PropTypes.bool,
  };

  static defaultProps = {
    id: '',
    label: '',
    tooltip: '',
    inlineGroup: false,
    required: false,
  };

  state = {
    error: '',
  };

  handleError = error => {
    this.setState(error);
  };

  renderLabel(tooltip, item) {
    const { id, label, inlineGroup, required } = this.props;
    if (!label) {
      return null;
    }

    const labelString = label.match(/\((.*?)\)/);

    let optionalLabel = '';
    let returnLabel = [];

    if (labelString) {
      optionalLabel = `(${labelString[1].toString()})`;
      returnLabel = label.split('(');
    }

    return (
      // eslint-disable-next-line jsx-a11y/label-has-for
      <Label
        id={id}
        label={returnLabel[0] || label}
        optionalLabel={optionalLabel}
        tooltip={tooltip}
        item={item}
        inlineGroup={inlineGroup}
        isRequired={required}
        aria-describedby={`${returnLabel[0] || label}-tooltip`}
      />
    );
  }

  renderToolTip() {
    const { tooltip } = this.props;

    if (!tooltip) {
      return null;
    }

    return <CustomToolTip tooltip={tooltip} />;
  }

  render() {
    const { id, children } = this.props;
    const isInput = children.type && children.type.name === 'Input';
    const { error } = this.state;
    const className = classNames(styles.wrapper, error ? styles[error] : null);
    return (
      <div className={className}>
        <div
          className={classNames(styles.labelWrapper, {
            [styles.column]: isInput,
          })}
        >
          {this.renderLabel(
            this.renderToolTip(),
            !isInput &&
              Children.map(children, child =>
                cloneElement(child, {
                  id,
                  onError: this.handleError,
                }),
              ),
          )}

          {isInput &&
            Children.map(children, child =>
              cloneElement(child, {
                id,
                onError: this.handleError,
              }),
            )}
        </div>
      </div>
    );
  }
}

export default FormGroup;
