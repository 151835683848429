import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/Card/components/CardFeatures/CardFeatures.scss';
import { Icon } from '@moonshineragency/ui/src';

class CardFeatures extends Component {
  static propTypes = {
    glyph: PropTypes.shape({}).isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
      .isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  render() {
    const { children, glyph, className } = this.props;
    return (
      <div
        className={(styles.container, className)}
        role="region"
        aria-label="Card Features"
      >
        <Icon className={styles.icon} glyph={glyph} />
        <div className={styles.wrapper}>{children}</div>
      </div>
    );
  }
}

export default CardFeatures;
