import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'components/Button/Button.scss';

class Button extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    /** Text or element */
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    /** indicates if Button is full-width */
    wide: PropTypes.bool,
    rounded: PropTypes.bool,
    /** Same padding on each side */
    square: PropTypes.bool,
    loading: PropTypes.bool,
    form: PropTypes.string,
    view: PropTypes.oneOf(['button', 'outline', 'link']),
    type: PropTypes.oneOf(['submit', 'reset', 'button', 'menu']),
    theme: PropTypes.oneOf(['unset', 'primary', 'secondary']),
    size: PropTypes.oneOf(['small', 'normal', 'large']),
    /** SyntheticEvent * */
    onClick: PropTypes.func,
    /** Creates Link or "a" tag if to is set * */
    to: PropTypes.string,
    target: PropTypes.string,
    isInternal: PropTypes.bool,
  };

  static defaultProps = {
    onClick: () => {},
    square: false,
    form: null,
    id: null,
    type: null,
    className: '',
    theme: 'primary',
    view: 'button',
    size: 'normal',
    wide: false,
    rounded: true,
    loading: false,
    disabled: false,
    to: null,
    target: null,
    isInternal: true,
  };

  setTag = () => {
    const { to, isInternal } = this.props;

    if (to) {
      return isInternal ? Link : 'a';
    }

    return 'button';
  };

  setClasses = () => {
    const {
      theme,
      size,
      wide,
      rounded,
      view,
      loading,
      square,
      className,
    } = this.props;

    return classNames(
      styles.container,
      styles[theme],
      styles[view],
      styles[size],
      {
        [styles.wide]: wide,
        [styles.square]: square,
        [styles.rounded]: rounded,
        [styles.pending]: loading,
      },
      className,
    );
  };

  render() {
    const {
      id,
      type,
      form,
      disabled,
      children,
      loading,
      onClick,
      to,
      target,
    } = this.props;

    const classes = this.setClasses();
    const Tag = this.setTag();

    return (
      <Tag
        id={id}
        className={classes}
        type={type}
        form={form}
        disabled={disabled || loading}
        onClick={onClick}
        href={to}
        to={to}
        target={target}
        aria-disabled={disabled || loading}
        aria-busy={loading}
      >
        {children}
      </Tag>
    );
  }
}

export default Button;
