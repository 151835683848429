import arrowDropdown from './arrowDropdown.svg';
import arrowLeft from './arrowLeft.svg';
import arrowLeftFull from './arrowLeftFull.svg';
import arrowNext from './arrowNext.svg';
import arrowRight from './arrowRight.svg';
import calendar from './calendar.svg';
import check from './check.svg';
import clock from './clock.svg';
import close from './close.svg';
import downloadIcon from './icon_download.svg';
import edit from './edit.svg';
import euroCircle from './euroCircle.svg';
import facebook from './facebook.svg';
import ginText from './gin_text.svg';
import globe from './globe.svg';
import infoCircle from './infoCircle.svg';
import instagram from './instagram.svg';
import linkedin from './linkedin.svg';
import logo from './logo.svg';
import mail from './mail.svg';
import menu from './menu.svg';
import phone from './phone.svg';
import phoneHours from './phoneHours.svg';
import pinLocation from './pinLocation.svg';
import plus from './plus.svg';
import quote from './quote.svg';
import twitter from './twitter.svg';

export {
  logo,
  infoCircle,
  clock,
  close,
  arrowLeft,
  arrowLeftFull,
  arrowRight,
  check,
  globe,
  arrowNext,
  menu,
  calendar,
  edit,
  pinLocation,
  plus,
  arrowDropdown,
  phone,
  phoneHours,
  quote,
  twitter,
  instagram,
  facebook,
  linkedin,
  mail,
  ginText,
  euroCircle,
  downloadIcon,
};
