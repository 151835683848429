import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Tooltip.scss';

class Tooltip extends Component {
  static propTypes = {
    labelText: PropTypes.string,
    delayTime: PropTypes.number,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    labelText: '',
    delayTime: 0,
    className: null,
  };

  state = {
    isVisible: false,
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  handleMouseEnter = () => {
    const { delayTime } = this.props;
    this.timer = setTimeout(() => {
      this.setState({
        isVisible: true,
      });
    }, delayTime);
  };

  handleMouseLeave = () => {
    clearTimeout(this.timer);
    this.setState({
      isVisible: false,
    });
  };

  render() {
    const { labelText, children, className } = this.props;
    const { isVisible } = this.state;
    return (
      <div className={classNames(styles.container, className)}>
        <div
          className={classNames(styles.wrapper, {
            [styles.isVisible]: isVisible,
          })}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          role="tooltip"
          aria-hidden={!isVisible}
        >
          <div className={styles.label}>{labelText}</div>
          {children}
        </div>
      </div>
    );
  }
}
export default Tooltip;
