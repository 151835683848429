import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/Card/components/CardImage/CardImage.scss';
import classNames from 'classnames';

class CardImage extends Component {
  static propTypes = {
    /** Sourcepath for asset */
    source: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { source, className } = this.props;
    return (
      <div>
        <img
          src={source}
          className={classNames(styles.image, className)}
          alt="CardImage"
        />
      </div>
    );
  }
}

export default CardImage;
