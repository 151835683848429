import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from 'components/Heading/Heading.scss';

class Heading extends Component {
  static propTypes = {
    isSmall: PropTypes.bool,
    isMedium: PropTypes.bool,
    isLarge: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    isInverted: PropTypes.bool,
    isSubHeading: PropTypes.bool,
    tag: PropTypes.string,
    children: PropTypes.node,
    noPadding: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    isSmall: false,
    isMedium: false,
    isLarge: false,
    isHighlighted: false,
    isInverted: false,
    isSubHeading: false,
    tag: 'h1',
    noPadding: false,
    className: '',
    children: '',
  };

  render() {
    const {
      tag,
      children,
      isHighlighted,
      isLarge,
      isMedium,
      isSmall,
      isInverted,
      isSubHeading,
      noPadding,
      className,
      ...props
    } = this.props;
    const Element = { tag };
    return (
      <Element.tag
        className={classNames(styles.heading, className, {
          [styles.small]: isSmall,
          [styles.highlighted]: isHighlighted,
          [styles.medium]: isMedium,
          [styles.large]: isLarge,
          [styles.inverted]: isInverted,
          [styles.subHeading]: isSubHeading,
          [styles[tag]]: !isSmall && !isLarge && !isMedium,
          [styles.noPadding]: noPadding,
        })}
        {...props}
      >
        {children}
      </Element.tag>
    );
  }
}
export default Heading;
