import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import DatePickerBase from 'components/DatePickerBase/DatePickerBase';
import FormGroup from 'components/FormGroup/FormGroup';

const DateWidget = ({
  id,
  title,
  description,
  value,
  required,
  options,
  onChange,
  rawErrors,
}) => {
  useEffect(() => {
    if (!value) {
      onChange(new Date());
    }
  });

  const getHint = () => {
    if (rawErrors) {
      return rawErrors.toString();
    }

    return null;
  };

  const handleChange = e => {
    const changedDate = dayjs(e).format('YYYY-MM-DD');
    if (changedDate === value) {
      return;
    }
    onChange(e === '' ? options.emptyValue : changedDate);
  };

  return (
    <FormGroup label={title} id={id} tooltip={description} required={required}>
      <DatePickerBase
        selected={new Date(value)}
        onDateChange={handleChange}
        hint={getHint}
      />
    </FormGroup>
  );
};

DateWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool.isRequired,

  options: PropTypes.shape({
    emptyValue: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  rawErrors: PropTypes.arrayOf(PropTypes.string),
};

DateWidget.defaultProps = {
  value: null,
  title: null,
  description: null,

  options: {
    emptyValue: '',
  },
  rawErrors: null,
};

export default DateWidget;
