import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TinySlider from 'tiny-slider-react';
import styles from 'components/Slider/Slider.scss';

const params = {
  lazyload: true,
  nav: false,
  mouseDrag: true,
  container: '#base',
  items: 1,
  slideBy: 'page',
  controls: false,
  swipeAngle: false,
  speed: 400,
};

const Slider = ({
  children,
  options,
  nextButton,
  prevButton,
  controlsClassName,
  onIndexChanged,
  slideTo,
  variant,
}) => {
  const sliderRef = useRef(null);

  const setRef = element => {
    sliderRef.current = element;
  };

  const onGoTo = direction => {
    sliderRef.current.slider.goTo(direction);
  };

  useEffect(() => {
    if (slideTo) onGoTo(slideTo.direction);
  }, [slideTo]);

  return (
    <div
      className={classNames(
        variant === 'hero' ? [styles.heroWrapper] : [styles.wrapper],
      )}
    >
      <TinySlider
        ref={setRef}
        settings={{ ...params, ...options }}
        onIndexChanged={onIndexChanged}
      >
        {children}
      </TinySlider>
      <div className={classNames(controlsClassName, styles.controls)}>
        {prevButton && (
          <button
            type="button"
            className={styles.prevArrow}
            onClick={() => onGoTo('prev')}
            aria-label={prevButton.label || 'Previous slide'}
          >
            <div className={styles.prevButton}>{prevButton.content}</div>
          </button>
        )}

        {nextButton && (
          <button
            type="button"
            className={styles.nextArrow}
            onClick={() => onGoTo('next')}
            aria-label={nextButton.label || 'Next slide'}
          >
            <div className={styles.nextButton}>{nextButton.content}</div>
          </button>
        )}
      </div>
    </div>
  );
};

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.shape({}),
  nextButton: PropTypes.shape({
    content: PropTypes.node,
    label: PropTypes.string,
  }),
  prevButton: PropTypes.shape({
    content: PropTypes.node,
    label: PropTypes.string,
  }),
  controlsClassName: PropTypes.string,
  onIndexChanged: PropTypes.func,
  slideTo: PropTypes.shape({
    direction: PropTypes.string,
    stamp: PropTypes.number,
  }),
  variant: PropTypes.string,
};

Slider.defaultProps = {
  options: {},
  nextButton: null,
  prevButton: null,
  controlsClassName: '',
  onIndexChanged: () => {},
  slideTo: null,
  variant: '',
};

export default Slider;
