import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/Checkbox/Checkbox';
import FormGroup from 'components/FormGroup/FormGroup';

class CheckboxWidget extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.bool,
    /* to control how the element should look like,you can write those options in ui:widget schema */
    options: PropTypes.shape({
      /* helper message  to show the user how the value should look like */
      help: PropTypes.string,
      emptyValue: PropTypes.string,
      defaultOption: PropTypes.string,
    }),
    /* rawErrors come from  react-jsonschema-form, when the value was invalid */
    rawErrors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: false,
    required: false,
    rawErrors: null,
    disabled: false,
    readonly: false,
    title: '',
    description: '',
    options: {
      defaultOption: '',
      emptyValue: '',
      help: '',
    },
  };

  getHint = () => {
    const {
      rawErrors,
      options: { help },
    } = this.props;

    if (rawErrors) {
      return rawErrors.toString();
    }

    if (help) {
      return help;
    }

    return null;
  };

  handleChange = event => {
    this.props.onChange(event.target.checked);
  };

  render() {
    const {
      id,
      required,
      disabled,
      readonly,
      value,
      title,
      description,
    } = this.props;
    return (
      <FormGroup
        label={title}
        id={id}
        tooltip={description}
        inlineGroup
        required={required}
      >
        <Checkbox
          id={id}
          disabled={disabled || readonly}
          checked={typeof value === 'undefined' ? false : value}
          onChange={this.handleChange}
          hint={this.getHint()}
          aria-checked={value}
          aria-required={required}
          aria-disabled={disabled || readonly}
        />
      </FormGroup>
    );
  }
}

export default CheckboxWidget;
