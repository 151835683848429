import Client from '@moonshineragency/client';
import models from 'api/models';

export const baseURL = `${process.env.BACKEND_URL}/api`;

const api = new Client({ baseURL }, models);

export const apiMultipart = new Client(
  { baseURL, shouldAcceptSet: false },
  models,
);

export default api;
