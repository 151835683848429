import React, { Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import track from 'react-tracking';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ScrollToTop } from '@moonshineragency/ui/src';

import Wrapper from 'components/Wrapper/Wrapper';
import Footer from 'pages/App/components/Footer/Footer';
import CookieBanner from 'pages/App/components/CookieBanner/CookieBanner';
import NotFound from 'pages/NotFound/NotFound';

/* Lazy loading components */
import Header from 'components/Header';

import CallDetail from 'pages/CallDetail';
import EventDetail from 'pages/EventDetail';
import Home from 'pages/Home';
import Page from 'pages/Page';
import Program from 'pages/Program';
import Contact from 'pages/Contact';
import dayjs from 'dayjs';
import utc from 'dayjs-plugin-utc';
import timezone from 'dayjs-timezone-iana-plugin';

import Skeleton from 'components/Skeleton/Skeleton';

import styles from 'pages/App/App.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Europe/Vienna');

const App = ({ isCookieConsentAccepted }) => {
  useEffect(() => {
    if (isCookieConsentAccepted) {
      const gtmInit = (s, l, i) => {
        window[l] = window[l] || [];
        window[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        const f = document.getElementsByTagName(s)[0];
        const j = document.createElement(s);
        const dl = l !== 'dataLayer' ? `&l=${l}` : '';
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        f.parentNode.insertBefore(j, f);
      };

      gtmInit('script', 'dataLayer', 'GTM-W2NRV87');
    }
  }, [isCookieConsentAccepted]);

  return (
    <Wrapper>
      <Suspense fallback={<Skeleton height={500} />}>
        <Header />
        <ScrollToTop>
          <main id="main" className={styles.main}>
            <Switch>
              <Route
                exact
                path="/index.html"
                render={() => <Redirect to="/" />}
              />
              <Route
                exact
                path="/startups.html"
                render={() => <Redirect to="/" />}
              />
              <Route
                exact
                path="/investors.html"
                render={() => <Redirect to="/investors" />}
              />
              <Route
                exact
                path="/incubators.html"
                render={() => <Redirect to="/incubators" />}
              />
              <Route
                exact
                path="/goAustria.html"
                render={() => <Redirect to="/programs/goAustria" />}
              />
              <Route
                exact
                path="/goAsia.html"
                render={() => <Redirect to="/programs/goAsia" />}
              />
              <Route
                exact
                path="/GINInvestorsLeaflet.pdf"
                render={() => {
                  window.location =
                    'https://gin-backend.moonshiner.dev/GIN%20Leaflet/GIN_Factsheet_Investors_Final.pdf';
                  return null;
                }}
              />

              <Route exact path="/" component={Home} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/calls/:slug/" component={CallDetail} />
              <Route exact path="/calls/:slug/:page" component={Page} />
              <Route exact path="/events/:slug" component={EventDetail} />
              <Route exact path="/programs/:slug" component={Program} />
              <Route exact path="/programs/:slug/:page" component={Page} />
              <Route exact path="/404" component={NotFound} />
              <Route exact path="/:slug/:extra" component={Page} />
              <Route exact path="/:slug" component={Page} />
              <Route component={NotFound} />
            </Switch>
          </main>
        </ScrollToTop>
        <Footer />
        <CookieBanner />
      </Suspense>
    </Wrapper>
  );
};

App.propTypes = {
  isCookieConsentAccepted: PropTypes.bool,
};

App.defaultProps = {
  isCookieConsentAccepted: null,
};

const mapStateToProps = state => ({
  isCookieConsentAccepted: state.app.isCookieConsentAccepted,
});

const enhance = compose(track(), connect(mapStateToProps));

export default enhance(App);
