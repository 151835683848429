import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/RadioButton/RadioButton.scss';
import classNames from 'classnames';

class RadioButton extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string]),
    tabIndex: PropTypes.number,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    readonly: PropTypes.bool,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
  };

  static defaultProps = {
    id: '',
    label: '',
    name: '',
    value: '',
    tabIndex: 0,
    required: false,
    disabled: false,
    readonly: false,
    onChange: () => {},
    checked: false,
  };

  handleLabelMouseDown = event => {
    event.preventDefault();

    if (this.input) {
      this.input.focus();
    }
  };

  handleChange = () => {
    this.props.onChange(this.props.value);
  };

  focus() {
    if (this.input && document.activeElement !== this.input) {
      this.input.focus();
    }
  }

  render() {
    const {
      id,
      name,
      value,
      tabIndex,
      checked,
      required,
      disabled,
      readonly,
      label,
    } = this.props;

    const radio = (
      <span>
        <input
          type="radio"
          name={name}
          id={id}
          tabIndex={tabIndex}
          value={value}
          onChange={this.handleChange}
          className={styles.input}
          checked={checked}
          required={required}
          disabled={disabled || readonly}
          aria-checked={checked}
          aria-disabled={disabled || readonly}
        />
        <span className={styles.label}>{label}</span>
      </span>
    );

    return (
      <div
        className={classNames(styles.wrapper, { [styles.checked]: checked })}
      >
        <label
          className={styles.mainLabel}
          htmlFor={id}
          onMouseDown={this.handleLabelMouseDown}
        >
          {radio}
        </label>
      </div>
    );
  }
}

export default RadioButton;
