import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@moonshineragency/ui/src';
import { check } from '@moonshineragency/icons';
import Tooltip from 'components/Tooltip/Tooltip';
import styles from 'components/Label/components/CustomToolTip/CustomToolTip.scss';

class CustomToolTip extends Component {
  static propTypes = {
    tooltip: PropTypes.string,
  };

  static defaultProps = {
    tooltip: '',
  };

  render() {
    const { tooltip } = this.props;
    return (
      <Tooltip className={styles.tooltip} labelText={tooltip}>
        <Icon glyph={check} className={styles.tooltipIcon} aria-hidden="true" />
      </Tooltip>
    );
  }
}

export default CustomToolTip;
