import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';

const rootElement = document.getElementById('root');
export const renderApp = AppComponent => {
  ReactDOM.render(
    <Provider store={store}>
      <AppComponent />
    </Provider>,

    rootElement,
  );
};
